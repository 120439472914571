import React from 'react'
import styled from 'styled-components'

import ReservationType from './ReservationType'

export default ({ reservationInfo }) => (
  <Container>
    {reservationInfo.map((type, i) => (
      <ReservationType
        key={i}
        heading={type.heading}
        paragraph={type.paragraph}
        button={type.button}
        image={type.image.childImageSharp}
      />
    ))}
  </Container>
)
const Container = styled.section`
  width: 100%;
  border-top: 10px solid ${props => props.theme.mainColor};
`
