import React from 'react'
import styled from 'styled-components'
import DatePicker, { Checkbox } from 'components/DatePicker'
import Button from 'components/Button'

const AvailabilityWidget = () => (
  <Container>
    <Heading>Check Availability</Heading>
    <SectionHeading>Select Dates</SectionHeading>
    <DatePicker />
    <Box>
      <Checkbox type="checkbox" />
      <Text>Check this box to make a daytime reservation only.</Text>
    </Box>
    <CheckAvailabilityButton blue to="/app/accommodations">
      Check Availability
    </CheckAvailabilityButton>
    <Group>
      If your group is larger than 50 people or you would like to reserve the
      entire camp please request a large group reservation.
    </Group>
    <GroupRequestButton to="/app/group-reservation">
      Large Group Reservation
    </GroupRequestButton>
  </Container>
)

export default AvailabilityWidget

const Container = styled.div`
  width: 360px;
  height: 530px;
  background: white;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  margin: 0 50vw 0 0;
  color: ${props => props.theme.fontColor};
  padding: 30px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;

  @media (max-width: ${props => props.theme.tablet}px) {
    width: 340px;
    margin: 20px auto;
    height: 530px;
  }

  @media (max-width: ${props => props.theme.phone}px) {
    width: initial;
    margin: 15px;
    padding: 20px;
  }
`

const Heading = styled.h2`
  font-family: ${props => props.theme.secondaryFont};
  font-size: 24px;
  text-align: center;
  font-weight: 600;
  line-height: 32px;
  margin: 10px 0;
`
const SectionHeading = styled.div`
  font-family: ${props => props.theme.secondaryFont};
  color: #aab4cb;
  font-size: 12px;
  text-align: center;
  font-weight: 300;
  font-style: italic;
  border-bottom: 1px solid #d5dceb;
  line-height: 24px;
  width: 100%;
`
const Box = styled.div`
  width: 80%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 26px;
`
const Text = styled.div`
  font-family: ${props => props.theme.secondaryFont};
  color: ${props => props.theme.mainColor};
  font-size: 14px;
  font-weight: ${props => props.theme.bold};
  font-style: italic;
  width: 75%;
  display: flex;
  align-items: center;
`
const CheckAvailabilityButton = styled(Button)`
  font-size: 18px;
`
const Group = styled.div`
  border-top: 1px solid #d5dceb;
  font-family: ${props => props.theme.secondaryFont};
  color: ${props => props.theme.fontColor};
  font-size: 14px;
  text-align: center;
  font-style: italic;
  font-weight: 400;
  padding-top: 20px;
  line-height: 18px;
  margin: 20px 0;
`
const GroupRequestButton = styled(Button)`
  font-size: 14px;
  line-height: 0;
  padding: 20px 25px;
  background-color: ${props => props.theme.fontColor};

  &:hover {
    background-color: #32394b;
  }
`
