import React from 'react'
import styled from 'styled-components'
import { Query, Mutation } from 'react-apollo'
import gql from 'graphql-tag'
import { ReactComponent as Checkmark } from 'icons/check.svg'

const TOGGLE_SINGLE_DAY = gql`
  mutation toggleSingleDay {
    toggleSingleDay @client
  }
`

const GET_SINGLE_DAY = gql`
  query {
    pickerRange @client {
      singleDay
    }
  }
`

export default () => (
  <Query query={GET_SINGLE_DAY}>
    {({ data: { pickerRange } }) => (
      <Mutation mutation={TOGGLE_SINGLE_DAY}>
        {toggle => (
          <Box checked={pickerRange.singleDay} onClick={toggle}>
            <Check />
          </Box>
        )}
      </Mutation>
    )}
  </Query>
)

const Box = styled.div`
  background: ${props => (props.checked ? props.theme.mainColor : 'white')};
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);
  transition-duration: 0.3s;
  margin-right: 10px;
  border: 1px solid #dbdbdb;
  width: 45px;
  height: 45px;
  min-width: 45px;
  min-height: 45px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: ${props => props.theme.phone}px) {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
  }
`
const Check = styled(Checkmark)`
  width: 25px;
  height: 25px;
`
