import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

export default ({
  children,
  className,
  component,
  blue,
  red,
  green,
  inactive,
  disabled,
  ...restProps
}) => {
  const colors = ['#2794F2', '#FF5353', '#33E276', '#D5DCEB']
  const hoverColors = ['#0A6DC3', '#C32027', '#0DB56E', '#D5DCEB']
  const colorIndex = blue ? 0 : red ? 1 : green ? 2 : 3
  const Button = component ? BaseButton.withComponent(component) : BaseButton
  const cursor = disabled ? 'not-allowed' : 'pointer'
  return (
    <Button
      color={colors[colorIndex]}
      cursor={cursor}
      hover={hoverColors[colorIndex]}
      className={className}
      {...restProps}
    >
      {children}
    </Button>
  )
}

const BaseButton = styled(Link)`
  display: block;
  padding: 25px 30px;
  border-radius: 25px;
  font-size: 22px;
  line-height: 0;
  text-align: center;
  font-weight: ${props => props.theme.semiBold};
  font-family: ${props => props.theme.primaryFont};
  color: white;
  transition-duration: 0.3s;
  cursor: ${props => props.cursor};
  background-color: ${props => props.color};
  border: none;

  &:hover {
    background-color: ${props => props.hover};
  }

  @media (max-width: ${props => props.theme.phone}px) {
    padding: 23px 20px;
    border-radius: 25px;
    font-size: 20px;
  }
`
