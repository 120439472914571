import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import Layout from 'components/PageLayout'
import SEO from 'components/SEO'
import Header from './Header'
import Reservations from './Reservations'
import Footer from 'components/Footer'
// import MapSection from './MapSection'

export const HomePageTemplate = ({ seo, header, reservationInfo, mapInfo }) => (
  <Fragment>
    <SEO title={seo.title} description={seo.description} />
    <Helmet title={seo.title} />
    <Header
      sizes={header.image.childImageSharp.fluid}
      title={header.title}
      paragraph={header.paragraph}
    />
    <Reservations reservationInfo={reservationInfo} />
    {/* <MapSection
      pin={mapInfo.pin}
      heading={mapInfo.heading}
      paragraph={mapInfo.paragraph}
      sizes={mapInfo.image.childImageSharp.sizes}
    /> */}
    <Footer />
  </Fragment>
)

export default ({ data }) => {
  const { frontmatter } = data.markdownRemark
  return (
    <Layout>
      <HomePageTemplate
        seo={frontmatter.seo}
        header={frontmatter.header}
        reservationInfo={frontmatter.reservations}
        mapInfo={frontmatter.mapSection}
      />
    </Layout>
  )
}

export const query = graphql`
  query HomePageQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        seo {
          title
          description
        }
        header {
          title
          paragraph
          image {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        reservations {
          heading
          paragraph
          image {
            childImageSharp {
              sizes(maxWidth: 2000) {
                ...GatsbyImageSharpSizes
              }
            }
          }
          button {
            text
            path
          }
        }
        mapSection {
          pin {
            lng
            lat
            mapMarker {
              childImageSharp {
                sizes(maxWidth: 2000) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
          }
          heading
          paragraph
        }
      }
    }
  }
`
