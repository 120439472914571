import React, { Component } from 'react'
import StyleWrapper from './picker-input-styles'
import { DateRangePicker, SingleDatePicker } from 'react-dates'
import Media from 'react-media'
import { graphql, compose } from 'react-apollo'
import gql from 'graphql-tag'
import moment from 'moment'

const GET_PICKER_RANGE = gql`
  query GetPickerRange {
    pickerRange @client {
      arriveDate
      leaveDate
      singleDay
    }
  }
`

export { default as Checkbox } from './Checkbox'

class DatePicker extends Component {
  state = {
    focusedInput: null,
    focused: null
  }

  render() {
    const { focusedInput, focused } = this.state
    const {
      pickerRange: { arriveDate, leaveDate, singleDay },
      loading,
      updatePicker,
      ...restProps
    } = this.props
    return (
      <StyleWrapper>
        <Media query="(max-width: 700px)">
          {matches =>
            singleDay ? (
              <SingleDatePicker
                id="singleDayPicker"
                placeholder="Arrive"
                date={arriveDate ? moment(arriveDate) : null}
                onDateChange={arriveDate =>
                  updatePicker({
                    variables: { arriveDate, leaveDate: arriveDate, singleDay }
                  })
                }
                onFocusChange={({ focused }) => this.setState({ focused })}
                focused={focused}
                orientation={matches ? 'vertical' : 'horizontal'}
                withFullScreenPortal={matches ? true : false}
                keepOpenOnDateSelect={matches ? true : false}
                numberOfMonths={1}
                {...restProps}
              />
            ) : (
              <DateRangePicker
                startDateId="startDate"
                startDatePlaceholderText="Arrive"
                startDate={arriveDate ? moment(arriveDate) : null}
                endDateId="endDate"
                endDatePlaceholderText="Leave"
                endDate={leaveDate ? moment(leaveDate) : null}
                onDatesChange={({ startDate, endDate }) => {
                  updatePicker({
                    variables: {
                      arriveDate: startDate,
                      leaveDate: endDate,
                      singleDay
                    }
                  })
                }}
                onFocusChange={focusedInput => {
                  this.setState({ focusedInput })
                }}
                focusedInput={focusedInput}
                orientation={matches ? 'vertical' : 'horizontal'}
                withFullScreenPortal={matches ? true : false}
                keepOpenOnDateSelect={matches ? true : false}
                numberOfMonths={2}
                {...restProps}
              />
            )
          }
        </Media>
      </StyleWrapper>
    )
  }
}

const UPDATE_PICKER = gql`
  mutation updatePicker(
    $arriveDate: String
    $leaveDate: String
    $singleDay: Boolean
  ) {
    updatePicker(
      arriveDate: $arriveDate
      leaveDate: $leaveDate
      singleDay: $singleDay
    ) @client {
      arriveDate
      leaveDate
      singleDay
    }
  }
`

export default compose(
  graphql(UPDATE_PICKER, { name: 'updatePicker' }),
  graphql(GET_PICKER_RANGE, {
    props: ({ data: { pickerRange }, loading }) => ({
      pickerRange,
      loading
    })
  })
)(DatePicker)
