import React from 'react'
import styled from 'styled-components'
import { transparentize } from 'polished'
import ImageLoader from '@merchantlabs/gatsby-image-loader'

import AvailabilityWidget from './AvailabilityWidget'

export default ({ title, paragraph, sizes }) => (
  <ImageLoader sizes={sizes}>
    {({ src }) => (
      <Background image={src}>
        <Section>
          <Text>
            <Title>{title}</Title>
            <Paragraph>{paragraph}</Paragraph>
          </Text>
          <AvailabilityWidget />
        </Section>
      </Background>
    )}
  </ImageLoader>
)

const Background = styled.div`
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  margin-top: 70px;
  padding: 60px 0;

  @media (max-width: ${props => props.theme.tablet}px) {
    margin-top: 0;
    background-attachment: scroll;
  }
`
const Section = styled.section`
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
  align-items: center;
  width: 100%;
  position: relative;

  @media (max-width: ${props => props.theme.tablet}px) {
    flex-direction: column;
  }
`
const Text = styled.div`
  width: 100vw;
  position: absolute;
  padding: 50px 30px 50px 43vw;
  background: linear-gradient(
    to right,
    ${transparentize(0.15, '#2794F2')},
    ${transparentize(0.15, '#2794F2')}
  );

  @media (max-width: ${props => props.theme.xl}px) {
    padding-left: 50vw;
  }

  @media (max-width: ${props => props.theme.desktop}px) {
    padding: 30px 20px 30px 52vw;
  }

  @media (max-width: ${props => props.theme.tablet}px) {
    position: relative;
    padding: 30px;
    margin: 60px 0 30px;
  }
`
const Title = styled.div`
  font-family: ${props => props.theme.mainFont};
  font-weight: ${props => props.theme.bold};
  font-size: 52px;
  color: white;
  margin-bottom: 20px;
  width: 40vw;

  @media (max-width: ${props => props.theme.desktop}px) {
    width: 100%;
    font-size: 45px;
  }

  @media (max-width: ${props => props.theme.tablet}px) {
    text-align: center;
    font-size: 30px;
  }
`
const Paragraph = styled.div`
  font-family: ${props => props.theme.secondaryFont};
  font-weight: ${props => props.theme.light};
  font-size: 24px;
  color: white;
  line-height: 35px;
  width: 40vw;

  @media (max-width: ${props => props.theme.desktop}px) {
    width: 100%;
    font-size: 20px;
    line-height: 30px;
  }

  @media (max-width: ${props => props.theme.tablet}px) {
    text-align: center;
    font-size: 18px;
  }
`
