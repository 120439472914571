import React from 'react'
import styled from 'styled-components'

import NavButton from 'components/Button'
import ImageLoader from '@merchantlabs/gatsby-image-loader'

export default ({ heading, paragraph, button, image }) => (
  <Container>
    <Text>
      <Heading>{heading}</Heading>
      <Paragraph>{paragraph}</Paragraph>
      <Button blue to={button.path}>
        {button.text}
      </Button>
    </Text>
    <ImageLoader sizes={image.sizes}>
      {({ src }) => <ImageContainer image={src} />}
    </ImageLoader>
  </Container>
)
const Container = styled.section`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 90px 70px;
  background-color: #efefef;

  &:nth-of-type(even) {
    flex-direction: row-reverse;
    background-color: white;
  }

  @media (max-width: ${props => props.theme.desktop}px) {
    padding: 50px 30px;
  }

  @media (max-width: ${props => props.theme.tablet}px) {
    flex-direction: column-reverse;

    &:nth-of-type(even) {
      flex-direction: column-reverse;
    }
  }
`
const Text = styled.div`
  width: calc(40% - 50px);
  max-width: 500px;

  @media (max-width: ${props => props.theme.desktop}px) {
    width: calc(40% - 20px);
  }

  @media (max-width: ${props => props.theme.tablet}px) {
    width: calc(100% - 60px);
    max-width: 100%;
  }

  @media (max-width: ${props => props.theme.phone}px) {
    width: 100%;
  }
`
const Heading = styled.h1`
  margin: 0 0 30px;
  font-size: 27px;
  color: ${props => props.theme.mainColor};
  font-family: ${props => props.theme.mainFont};
  font-weight: ${props => props.theme.bold};
  text-transform: uppercase;
`
const Paragraph = styled.p`
  font-size: 18px;
  line-height: 26px;
  color: ${props => props.theme.fontColor};
  font-family: ${props => props.theme.secondaryFont};
  font-weight: ${props => props.theme.regular};
`
const Button = styled(NavButton)`
  margin: 30px 0;
  max-width: 220px;
`
const ImageContainer = styled.div`
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30vw;
  width: calc(60% - 30px);
  box-shadow: 5px 15px 80px rgba(0, 0, 0, 0.3);

  &:nth-of-type(even) {
    box-shadow: -5px 15px 80px rgba(0, 0, 0, 0.3);
  }

  @media (max-width: ${props => props.theme.desktop}px) {
    height: 50vw;
    width: calc(50% - 10px);
  }

  @media (max-width: ${props => props.theme.tablet}px) {
    width: calc(100% - 60px);
    margin-bottom: 50px;
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.2);

    &:nth-of-type(even) {
      box-shadow: 0 10px 40px rgba(0, 0, 0, 0.2);
    }
  }

  @media (max-width: ${props => props.theme.phone}px) {
    width: 100%;
    margin-bottom: 30px;
  }
`
